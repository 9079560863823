import React, { useContext, useEffect, useState } from 'react'
import { Helmet } from 'react-helmet'

// Components
import ComicList from '../../organisms/ComicList'
import DefaultHelmet from '../../atoms/DefaultHelmet'

// Config
import { comic } from '../../../config/localization'

// Context
import LocalizationContext from '../../../context/Localization/Localization.context'

const ComicTemplate: React.FC = () => {
  const { language } = useContext(LocalizationContext)
  const [localization, setLocalization] = useState(comic.Default)

  useEffect(() => {
    const newLocalization = comic[language]
    if (typeof newLocalization !== 'undefined') {
      setLocalization(newLocalization)
    }
  }, [language])

  return (
    <>
      <Helmet>
        <title>
          Comic - Dilan
          {language === 'ESP'
            ? ' las crónicas de Covak'
            : ' The chronicles of Covak'}
        </title>
        <meta
          name="description"
          content={
            language === 'ESP'
              ? 'Encuentra todos los capítulos de Dilan: las crónicas de covak en un solo lugar.'
              : 'Find all of Dilan the chronicles of Covak chapters in a single place.'
          }
        />
        <meta
          name="keywords"
          content={
            language == 'ESP'
              ? 'Dilan Covak, webcomic, comic, comic gratis, fantasia, magia, espadas, peleas, Dilan, blanco y negro, capítulos, volumenes'
              : 'Dilan Covak, webcomic, comic, free comic, fantasy, magic, swords, fights, Dilan, black and white, chapters, volumes'
          }
        />
        <DefaultHelmet />
      </Helmet>
      <ComicList language={language} localization={localization} />
    </>
  )
}

export default ComicTemplate
